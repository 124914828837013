import React from "react"
import { Authenticated2 } from "../components/auth/Authenticated2"
import { EnsureEmailVerifiedViaRedirect } from "../components/auth/VerifyEmail"
import { BodyWrapper } from "../components/layout/BodyWrapper"
import { Layout } from "../components/layout/Layout"
import { LoadingPage } from "../components/layout/LoadingPage"
import { Nav } from "../components/layout/Nav"
import { PageHeading } from "../components/layout/PageHeading"
import { PageTitle } from "../components/typing-test/PageTitle"

export default function TypingPractice() {
  return (
    <>
      <PageTitle />
      <Authenticated2 loading={<LoadingPage />}>
        <Layout>
          <Nav showBottomBorder />
          <BodyWrapper>
            <EnsureEmailVerifiedViaRedirect />
            <PageHeading>Typing Practice</PageHeading>
            <p>Typing practice</p>
          </BodyWrapper>
        </Layout>
      </Authenticated2>
    </>
  )
}
